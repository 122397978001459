import {websiteLayout} from '@data/fragments/website/__generated__/websiteLayout'

export default function useWebsiteLayout(website: websiteLayout) {
  const layout = website && website.layout ? website.layout : 'default'
  const menuLayout = website && website.menuLayout ? website.menuLayout : 'v1'
  const productsLayout = website && website.productsLayout ? website.productsLayout : 'long'
  const checkoutLayout = 'v3'
  const headerLayout = website && website.headerLayout ? website.headerLayout : 'default'
  return {layout, menuLayout, productsLayout, checkoutLayout, headerLayout}
}
